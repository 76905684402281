import loadScript from '@elements/load-script';
import { find, findAll, findAllIn, findIn, addClass, removeClass, on, closest } from '@elements/dom-utils';
import { trigger } from '@elements/dom-utils';
import { initInScope as initWatchlistHearts } from './watchList';

const selectors = {
    map: '.js-contwise-map',
    mapArea: '.js-contwise-map-area',
    itemListWrapper: '.js-contwise__item-list',
    singleItemTeaser: '.js-contwise__item-teaser',
    mapAjaxForm: '.js-contwise-map__ajax-form',
    scrollContainer: '.js-contwise__item-list-scroll-container',
    tabPane: '.js-contwise__map-tab-pane'
};

const zoomDurationMs = 1100;

export function init() {
    if(!_config.generalSolutionsMapEndpoint || !_config.generalSolutionsMapTeaserUrl || !_config.generalSolutionsMapTeaserUrlFiltered) {
        console.error("config variables missing - please check template, if all necessary variables are set")
        console.error("generalSolutionsMapEndpoint: ", _config.generalSolutionsMapEndpoint)
        console.error("generalSolutionsMapTeaserUrl: ", _config.generalSolutionsMapTeaserUrl)
        console.error("generalSolutionsMapTeaserUrlFiltered: ", _config.generalSolutionsMapTeaserUrlFiltered)
        return
    }


    loadScript(_config.generalSolutionsMapEndpoint).then(() => {
        const mapContentAreas = findAll(selectors.mapArea)
        if(!mapContentAreas) {
            console.warn("mapContentAreas not existing")
            return
        }

        console.log("map content areas", mapContentAreas)

        handleMapContentAreas(mapContentAreas);
        // since the whole content area gets replaced by the ajax form, we need to reinitialize the map
        $(selectors.mapAjaxForm).on('success.ajax-form', (evt) => {
            handleMapContentAreas(mapContentAreas);
        });

        function handleMapContentAreas(mapContentAreas) {
            mapContentAreas.forEach((contentArea, index) => {
                const mapTabPane =  closest(selectors.tabPane, contentArea);
                console.log("mapTabPane", mapTabPane)

                const mapTabPaneTeaserUrl = mapTabPane?.dataset.mapTeaserUrl || _config.generalSolutionsMapTeaserUrl || null;

                if(!mapTabPaneTeaserUrl) {
                    console.error("mapTabPaneTeaserUrl not configured for map CA", contentArea);
                    return
                }

                console.log("mapTabPaneTeaserUrl", mapTabPaneTeaserUrl)

                const listWrapper = findIn(selectors.itemListWrapper, contentArea);
                console.log("listWrapper", listWrapper)

                const map = findIn(selectors.map, contentArea);
                map.dataset['mapId'] = index;

                if(!listWrapper || !map) {
                    console.warn("listWrapper or map not existing")
                    return
                }

                console.log("map", map)

                // state to save POIs inside CA instance
                let allCurrentUniquePois = { foreignIds: [] }

                // interaction-related state
                let currentlyHasSelectedTeaser = false
                let maxedOutUpdateEvent = null // event { detail: { mapElements: [] } }
                let currentlySelectedTeaser = null
                let lastZoomAnimationTriggered = null

                fetch(appendCurrentQueryParamsToUrl(mapTabPaneTeaserUrl, index)).then((response) => {
                    return response.json();
                }).then((response) => {
                    console.log("response", response)
                    listWrapper.innerHTML = response.html;

                    trigger('async-append', listWrapper)
                }).catch("error: ", console.error);

                map.addEventListener('map-element-onclick', (e) => {
                    e.stopPropagation()
                    unhighlightTeasers(findAllIn(selectors.singleItemTeaser, listWrapper))
                    highlightTeaser(e.detail, listWrapper)
                });

                map.addEventListener('map-element-onmouseover', (e) => {
                    e.stopPropagation()
                    unhighlightTeasers(findAllIn(selectors.singleItemTeaser, listWrapper))
                    highlightTeaser(e.detail, listWrapper)
                });

                map.addEventListener('map-element-onmouseout', (e) => {
                    e.stopPropagation()
                    unhighlightTeasers(findAllIn(selectors.singleItemTeaser, listWrapper))
                });

                map.addEventListener('map-update', (e) => {
                    console.log("event map-update", e, "from map id", e.target.dataset.mapId)

                    maxedOutUpdateEvent = maxedOutUpdateEvent ? {
                        detail: {
                            mapElements: [...maxedOutUpdateEvent.detail.mapElements, ...e.detail.mapElements].reduce((acc, curr) => {
                                const duplicate = acc.find(item => item.foreignId === curr.foreignId);
                                return duplicate ? acc : [...acc, curr];
                            }, [])
                        }
                    } : e;

                    const lastZoomStillActive = Date.now() - lastZoomAnimationTriggered < zoomDurationMs;
                    if(lastZoomStillActive || currentlyHasSelectedTeaser) {
                        return;
                    }

                    if(updateHasForeignIds(e)) {
                        updateVisibleListItems(e.detail.mapElements, listWrapper);
                        console.log("updaing listitems in wrapper", listWrapper, "based on event called in index", index, "with detail", e.detail.mapElements.length)

                    }
                    else if(updateHasForeignIds(maxedOutUpdateEvent)) {
                        console.log("== debug calling updateVisibleListItems with maxedOutUpdateEvents[index].value.detail.mapElements", maxedOutUpdateEvent.detail.mapElements, "for listwrapper", listWrapper, "and index", index)
                        updateVisibleListItems(maxedOutUpdateEvent.detail.mapElements, listWrapper);
                        findIn(selectors.scrollContainer, contentArea)?.scrollTo({top: 0, behavior: 'smooth'})
                    }
                });

                listWrapper.addEventListener('async-append', (asyncAppendEvent) => {
                    resetUniquePois(allCurrentUniquePois);
                    // init watchlist hearts
                    initWatchlistHearts($(contentArea));
                    findAllIn(selectors.singleItemTeaser, listWrapper).forEach((teaser) => {
                        if(teaser.dataset.hasHandler) {
                            return;
                        }

                        teaser.dataset.hasHandler = true;
                        addUniquePoi(teaser, allCurrentUniquePois)
                        teaser.addEventListener('click', () => {
                            lastZoomAnimationTriggered = Date.now();
                            currentlyHasSelectedTeaser = true;

                            updateVisiblePois([teaser.dataset.foreignId], map);
                            currentlySelectedTeaser && removeClass('map-teaser--selected', currentlySelectedTeaser);

                            if(currentlySelectedTeaser == teaser) {
                                currentlyHasSelectedTeaser = false;
                                currentlySelectedTeaser = null;

                                if(maxedOutUpdateEvent && updateHasForeignIds(maxedOutUpdateEvent)) {
                                    updateVisibleListItems(maxedOutUpdateEvent.detail.mapElements, listWrapper, false, false)
                                }

                                updateVisiblePois(allCurrentUniquePois.foreignIds, map);
                                return;
                            }

                            if(isMobile()) {
                                map.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
                            } else {
                                teaser.scrollIntoView({behavior: "smooth", block: "nearest"});
                            }

                            addClass('map-teaser--selected', teaser);
                            currentlySelectedTeaser = teaser
                        })
                    })


                    allCurrentUniquePois.foreignIds.length > 0 && updateVisiblePois(allCurrentUniquePois.foreignIds, map);
                    console.log("allCurrentUniquePois.foreignIds", allCurrentUniquePois.foreignIds)
                });
            });

        }
    }).catch("error: ", console.error);
}

function resetUniquePois(allUniquePois) {
    allUniquePois.foreignIds = [];
}

function addUniquePoi(teaser, allUniquePois) {
    teaser.dataset.foreignId && allUniquePois.foreignIds.push(teaser.dataset.foreignId);
}

function updateVisiblePois(currentVisiblePois, map) {
    const stringIds = currentVisiblePois.join(',')
    map.setAttribute("resourceforeignids", stringIds)
}

const updateVisibleListItems = (maxedOutUpdateEventDetail, itemList) => {
    const singleTeasers = findAllIn(selectors.singleItemTeaser, itemList)
    singleTeasers.forEach((teaser) => {
        const shouldBeVisible = arrayContainsObject(maxedOutUpdateEventDetail, teaser.dataset['foreignId'], 'foreignId');
        if(shouldBeVisible) {
            removeClass('map-teaser--hidden', teaser);
        }
        else {
            addClass('map-teaser--hidden', teaser);
        }
    })
}

function highlightTeaser(eventDetail, listWrapper) {
    const foreignId = eventDetail.foreignId
    const listTeaser = findTeaser(listWrapper, foreignId, 'foreignId')

    if(listTeaser) {
        listTeaser.scrollIntoView({behavior: "smooth", block: "nearest"});
        listTeaser.addClass('map-teaser--is-highlighted');
    }
}

function unhighlightTeasers(teasers) {
    console.log("teasers", teasers)
    teasers.forEach((teaser) => {
        removeClass('map-teaser--is-highlighted', teaser)
    })
}

function findTeaser(itemListWrapper, idToFind, fieldToMatch) {
    const teasers = findAllIn(selectors.singleItemTeaser, itemListWrapper)

    const matchingTeasers = teasers.find((teaser) => teaser.dataset[fieldToMatch] == idToFind)
    return matchingTeasers
}

function arrayContainsObject(array, idToFind, fieldToMatch) {
    return array.some((elem) => elem[fieldToMatch] == idToFind)
}

function appendCurrentQueryParamsToUrl(baseUrl, callIndex = null) {
    const dummyHost = 'http://dummy-host'; // the dummy host is just for correctly creating an URL object
    const url = new URL(baseUrl, dummyHost);
    const currentSearchParams = new URLSearchParams(window.location.search);

    currentSearchParams.forEach((value, key) => {
        url.searchParams.append(key, value);
    });

    if(callIndex) {
        url.searchParams.set('asyncCallIndex', callIndex);
    }

    return url.pathname + url.search;
}

function updateHasForeignIds(maxedOutUpdateEvent) {
    return maxedOutUpdateEvent && maxedOutUpdateEvent.detail.mapElements.some((elem) => elem.foreignId)
}

function isMobile() {
    return window.innerWidth < 768;
}